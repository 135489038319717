import React from "react";
import Logo from "../components/logo";
import Footer from "../components/footer";
import Container from "../components/container";
import { Link } from "gatsby";



export default function About() {
  return(
  <div>
    
    <Container title="About">
    <Logo/>
      <hr />
      <h2>About me</h2>
      <p>Hey, my name is Antoine W. and I'm from France. Here's a few things I do:</p>
      <h2>Web Development</h2>
      <p>
        <Link to="/"><i>This Blog</i></Link>, and other sites, using React, Gatsby, Firebase amonth other techs.
      </p>
      <h2>iOS Development</h2>
      <p>
        <i>Review Chinese</i> , an App to review Chinese words, through the use
        of smart flash-cards.
      </p>
      <p>
        <i>Revive</i> , an App to keep track of recurring tasks and save your
        progress along the way.
      </p>
      <h2>Chinese</h2>
      <p>
        I can speak chinese in daily-life. I can read intermediate level chinese texts.
      </p>
      <h2>Law</h2>
      <p>
        I majored in Corporate and Tax Law, with a additional cursus in Chinese Law. After an internship in
        Shanghai, I succeeded the french bar examination in 2019. 
      </p>
      <hr />
      <Footer />
    </Container>
  </div>
  )
};


